
<div class="CountDown">

    <div class="boxHolder">
        <div class="box">
            <div class="boxTxt">{{day}}</div>
        </div>
        <div class="boxTitle">DAYS</div>
    </div>
    <div class="separator">
        <div class="separatorTxt">:</div>
    </div>

    <div class="boxHolder">
        <div class="box">
            <div class="boxTxt">{{hour}}</div>
        </div>
        <div class="boxTitle">HOURS</div>
    </div>
    <div class="separator">
        <div class="separatorTxt">:</div>
    </div>

    <div class="boxHolder">
        <div class="box">
            <div class="boxTxt">{{minute}}</div>
        </div>
        <div class="boxTitle">MINS</div>
    </div>
    <div class="separator">
        <div class="separatorTxt">:</div>
    </div>

    <div class="boxHolder">
        <div class="box">
            <div class="boxTxt">{{second}}</div>
        </div>
        <div class="boxTitle">SECS</div>
    </div>

</div>