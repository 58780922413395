import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { gsap, TweenMax, TimelineMax, Quint, Quart, Quad } from 'gsap'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { YouTubePlayerModule, YouTubePlayer } from '@angular/youtube-player';

import { Movie, MovieService } from '../../services/movie/movie.service'


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public isVisibleMoreInfo: boolean = false
    private started: boolean = false
    private count: number = 0
    tl: any
    intervalNo: any
    isPopupOpen: boolean = false
    isPopupVideoOpen: boolean = false
    private videoLoop: ElementRef;
    ytWidth: number
    ytHeight: number
    intervalVid: any
    lastPercentageTracked: number = -1
    year: any = ''
    // private youtubePlayer: YouTubePlayer;

    @ViewChild('loopvideo', { static: false }) set content(content: ElementRef) {
        if (content) {
            this.videoLoop = content;
            this.videoLoop.nativeElement.muted = true
            this.videoLoop.nativeElement.play().catch((err) => {
                console.error('Video playback error');
            });
        }
    }

    // @ViewChild('youtubePlayer', { static: false } ) set contentYt(content: ElementRef) {
    //     this.youtubePlayer = content
    // }

    @ViewChild(YouTubePlayer) youtubePlayer: YouTubePlayer;


    constructor(
        public movieService: MovieService,
        private gtmService: GoogleTagManagerService,
    ) {
        this.year = (new Date()).getFullYear()
     }

    ngOnInit(): void {
        const tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);

        this.tl = new TimelineMax()
        gsap.set('.moreInfo', { display: 'none' });

        this.movieService.getCurrent()

        this.intervalNo = setInterval(() => {
            this.movieService.getCurrent()
        }, 1000);

        let gtmTag = {
            'event': "dataLayer-initialized",
            'titleName': "Lionsgate.live",
            'dxNumbers': undefined,
            'Genre': undefined,
            'environment': 'prod',
            'Distribution Window': undefined,
            'searchTerm': undefined,
            'pageContentType': undefined,
            'siteType': 'lionsgate live',
            'domainName': "lionsgate.live",
            'urlFragment': undefined
        }
        // this.gtmService.addGtmToDom();

        this.gtmService.pushTag(gtmTag);
        this.onWindowResize(null)

        setTimeout(() => {
            try {
                window['truste'].eu.icon.initialize();
            }
            catch (e) {
                console.log('TrustArc init error');
            }
        }, 1500);
    }

    trackVideoEvent(status) {
        try {
            let gtmTag = {
                event: 'video_event',
                property_title: 'Thank you video',
                video_status: status, //'<play|pause|reached X%>',
                video_title: 'Thanks To All Who Joined Us | LionsgateLIVE',
                video_id: this.youtubePlayer.videoId,
                video_length: Math.round(this.youtubePlayer.getDuration())
            }
            this.gtmService.pushTag(gtmTag);

        }
        catch (e) {
            console.error('tracking error: ', e);
        }
    }

    onPlaying() {
        let videoTime = this.youtubePlayer.getCurrentTime();
        let videoDuration = this.youtubePlayer.getDuration();
        
        let percentage = ((100 / videoDuration) * videoTime);
        if(percentage >= 25 && percentage < 50 && this.lastPercentageTracked < 25) {
            this.lastPercentageTracked = 25;
            this.trackVideoEvent('25%');
        }
        if(percentage >= 50 && percentage < 75 && this.lastPercentageTracked < 50) {
            this.lastPercentageTracked = 50;
            this.trackVideoEvent('50%');
        }
        if(percentage >= 75 && percentage < 100 && this.lastPercentageTracked < 75) {
            this.lastPercentageTracked = 75;
            this.trackVideoEvent('75%');
        }
    }

    ytStateChange(event) {
        console.log(event) 
        switch(event.data) {
            case 0 : {
                this.trackVideoEvent('100%')
                clearInterval(this.intervalVid)
                break
            }
            case 1 : {
                this.trackVideoEvent("play")
                clearInterval(this.intervalVid)
                this.intervalVid = setInterval(() => {
                    this.onPlaying()
                }, 1000)
                break
            }
            case 2 : {
                this.trackVideoEvent("pause")
                clearInterval(this.intervalVid)
                break
            }
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        // console.log("scrolling...");
        if (window.scrollY > 30) {
            this.isVisibleMoreInfo = false
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize($event) {
        // 640 : 390 = w : h
        // w = 640 * h / 390
        // h = 390 * w / 640

        let aspect = 640 / 390;
        let scale = 0.8
        console.log("<resize>");
        if (window.innerWidth <= 640 || window.innerHeight <= 640) {
            scale = 1
        }

        this.ytWidth = window.innerWidth * scale
        this.ytHeight = this.ytWidth / aspect;

        if (this.ytHeight > window.innerHeight * scale) {
            this.ytHeight = window.innerHeight * scale
            this.ytWidth = this.ytHeight * aspect
        }
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event) {
        console.log("onBlur")
        this.videoLoop && this.videoLoop.nativeElement.pause()
    }

    @HostListener('window:focus', ['$event'])
    onFocus(event) {
        console.log("onFocus")
        this.videoLoop && this.videoLoop.nativeElement.play()
        
    }

    trackStreamClick(url: string) {
        let gtmTag = {
            'event': "cta_click",
            'click_text': "Stream Live",
            'click_url': url
        }
        this.gtmService.pushTag(gtmTag);
    }

    trackDonateClick() {
        let gtmTag = {
            'event': "cta_click",
            'click_text': "Donate Now",
            'click_url': "http://smartmailerclick.bisglobal.net/act_linkClick.aspx?sm_guid=MzMzMDMxfDB8LTF8fDI2NDE2NjN8aHR0cHM6Ly93ZWIuY2hhcml0eWVuZ2luZS5uZXQvRGVmYXVsdC5hc3B4P3RzaWQ9NjY3NHwwfDB8MHw5MDN8MHwwfHwzMDE0MDY1"
        }
        this.gtmService.pushTag(gtmTag);
    }

    trackSnackNation() {
        let gtmTag = {
            'event': "cta_click",
            'click_text': "SnackNation",
            'click_url': "http://snacknation.com/movie"
        }
        this.gtmService.pushTag(gtmTag);
    }

    trackPopcornpolis() {
        let gtmTag = {
            'event': "cta_click",
            'click_text': "Popcornopolis",
            'click_url': "https://www.popcornopolis.com"
        }
        this.gtmService.pushTag(gtmTag);
    }


    onImageLoad() {
        this.count++
        console.log(`image loaded: ${this.count}`);
        if (this.count > 4 && !this.started) {
            this.started = true
            this.startupAnim()
        }
    }

    startupAnim() {



        gsap.set('.stagger', { y: 100, opacity: 0 });

        gsap.to('.topWrapper', 0.3, { opacity: 1, ease: Quad.easeOut })
        gsap.to('.middleWrapper', 0.3, { opacity: 1, ease: Quad.easeOut })
        gsap.to('.bottomWrapper', 0.3, { opacity: 1, ease: Quad.easeOut })
        gsap.set('.moreInfo', { display: 'flex' });
        setTimeout(() => {
            this.tl = new TimelineMax()
            this.tl.staggerTo('.stagger', 0.3, { opacity: 1, y: 0, ease: Quart.easeOut }, 0.2)
        }, 2000)

        setTimeout(() => {
            this.isVisibleMoreInfo = true
        }, 3000)

    }

    openPopup() {
        this.isPopupOpen = true
    }

    closePopup() {
        this.isPopupOpen = false
    }

    openVideo() {
        this.isPopupVideoOpen = true
        this.videoLoop && this.videoLoop.nativeElement.pause()
        this.youtubePlayer.playVideo()
        
        // this.playback()
    }

    closeVideo() {
        this.isPopupVideoOpen = false
        this.youtubePlayer.stopVideo()
        
        this.videoLoop && this.videoLoop.nativeElement.play()
    }
}
