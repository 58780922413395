import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment-timezone';

export class Movie {
    startDate: string
    endDate: string
    displDate: string
    background: string
    backgroundMobile: string
    title: string
    expired: boolean
    running: boolean
    url: string
    logoUrl: string
}

@Injectable({
    providedIn: 'root'
})
export class MovieService {
    public movies: Movie[]
    public currentMovie: Movie

    private nowOverWritten: any
    
    constructor(
        private http: HttpClient
    ) {
        this.movies = [
            {
                startDate: '17 Apr 2020 18:00:00 PDT',
                endDate: '17 Apr 2020 20:45:00 PDT',
                background: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-top.jpg',
                backgroundMobile: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-top-v3.jpg',
                title: 'The Hunger Games',
                expired: false,
                running: false,
                displDate: "APRIL 17",
                url: 'https://youtu.be/fID-f_Ao9ag',
                logoUrl: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/the-hunger-games-logo.png'
            },
            {
                startDate: '24 Apr 2020 18:00:00 PDT',
                endDate: '24 Apr 2020 19:40:00 PDT',
                background: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-top-dirty-dancing.jpg',
                backgroundMobile: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-top-dirty-dancing.jpg',
                title: 'Dirty Dancing',
                expired: false,
                running: false,
                displDate: "APRIL 24",
                url: 'https://youtu.be/4cnJ8g4Hdo4',
                logoUrl: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/dirty-dancing-title.png'
            },
            {
                startDate: '1 May 2020 18:00:00 PDT',
                endDate: '1 May 2020 20:16:00 PDT',
                background: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-top-lalaland.jpg',
                backgroundMobile: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-top-lalaland.jpg',
                title: 'La La Land',
                expired: false,
                running: false,
                displDate: "MAY 1",
                url: 'https://youtu.be/45q1HEbI3bE',
                logoUrl: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/lalaland-title.png'
            },       
            {
                startDate: '8 May 2020 18:00:00 PDT',
                endDate: '8 May 2020 19:49:00 PDT',
                background: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-top-john-wick-v2.jpg',
                backgroundMobile: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-top-john-wick.jpg',
                title: 'John Wick',
                expired: false,
                running: false,
                displDate: "MAY 8",
                url: 'https://youtu.be/BzkTHqXmhYQ',
                logoUrl: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/john-wick-title.png'
            }, 
            {
                startDate: '8 May 2020 19:49:59 PDT',
                endDate: '8 May 2020 19:50:01 PDT',
                background: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-top-thankyou-short.jpg',
                backgroundMobile: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-top-thankyou-short.jpg',
                title: '',
                expired: true,
                running: false,
                displDate: "-",
                url: 'https://youtu.be/BzkTHqXmhYQ',
                logoUrl: 'https://d3szci3apkzsqe.cloudfront.net/assets/img/john-wick-title.png'
            }  
        ]

        this.nowOverWritten = null
        try {
            let hash = decodeURIComponent(window.location.hash).replace('#', '')

            if(hash.length> 11) {
                this.nowOverWritten = new moment.tz(hash, "America/Los_Angeles")
            }
        }
        catch(e) {}

        // geo redirct
        let json = this.http.get('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')

		json.subscribe(data=> {
            console.log(data);
            let loc: any
            loc = data
			if(loc.country_code == 'GB') {
                // redirect 
                // window.location.href = 'https://lionsgate.live/uk/'
                // window.location.href = 'https://hybridstudio.com/projects/lionsgateliveuk/build/'
            }

		}, error=> {
			console.log(error);
		})

        this.getCurrent()
    }

    getCurrent() {
        let now = new moment()

        
        if(this.nowOverWritten != null ) {
            now = this.nowOverWritten
        }
        // now = new moment.tz('17 Apr 2020 20:23:00 PDT', "America/Los_Angeles")

        let done : boolean = false

        this.movies.forEach(movie => {
            let startDate = moment.tz(movie.startDate, "America/Los_Angeles")
            let endDate = moment.tz(movie.endDate, "America/Los_Angeles")
            if(!done) {
                if( now.isBefore(startDate) ) {
                    this.currentMovie = movie
                    done = true
                    // we are done
                }
                else {
                    if( now.isBefore(endDate)) {
                        movie.running = true
                        movie.displDate = "NOW PLAYING"
                        this.currentMovie = movie
                        done = true
                    }
                    else {
                        movie.displDate = "ENDED"
                        movie.running = false
                        movie.expired = true
                    }
                }
            }

        });

        if (!done) {
            let movie = this.movies[4]
            let startDate = moment.tz(movie.startDate, "America/Los_Angeles")
            let endDate = moment.tz(movie.endDate, "America/Los_Angeles")
            this.currentMovie = movie
        }
    }
}
