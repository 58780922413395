import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { HomeComponent } from './page/home/home.component';

@NgModule({
    declarations: [
        AppComponent,
        CountdownComponent,
        HomeComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        YouTubePlayerModule
    ],
    providers: [
        {provide: 'googleTagManagerId',  useValue: 'GTM-T9Q8QNQ'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
