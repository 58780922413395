
<div class="homeHolder">
    <div class="moreInfo desktopOnly " [ngClass]="{'blinking' : isVisibleMoreInfo, 'fadeout' :  !isVisibleMoreInfo }">
        <div class="text">
            MORE<br>INFO 
        </div>
        <div class="icon"></div>
    </div>
    <div class="topWrapper">
        <img class="desktopOnly" [src]="movieService.currentMovie.background" alt="" (load)="onImageLoad()">
        <img class="mobileOnly" [src]="movieService.currentMovie.backgroundMobile" alt="" (load)="onImageLoad()">
        <div class="topHolder stagger">
            <div class="left"></div>
            <div class="right" [class]="{'endScreen': movieService.currentMovie.expired}">
                <div class="titlePart shrink" [class]="{'endScreen': movieService.currentMovie.expired}">
                    <div class="p1" *ngIf="!movieService.currentMovie.expired">
                        <div class="title" *ngIf="!movieService.currentMovie.running">
                            {{movieService.currentMovie.title}}
                        </div>
                        <div class="title" *ngIf="movieService.currentMovie.running">
                            Now Streaming Live
                        </div>
                        <app-countdown *ngIf="!movieService.currentMovie.running" [countdownDate]="movieService.currentMovie.startDate"></app-countdown> 

                        <div class="liveNow" *ngIf="movieService.currentMovie.running" >
                            <img [src]="movieService.currentMovie.logoUrl" alt="">
                        </div>
                    </div>
                    <div class="p1 endWrapper" *ngIf="movieService.currentMovie.expired">
                        <div class="endImageHolder desktopOnly">
                            <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/willrogers-thinlong-logo.jpg" alt="">
                        </div>
                        <div class="endDonateHolder desktopOnly">
                            <a href="http://smartmailerclick.bisglobal.net/act_linkClick.aspx?sm_guid=MzMzMDMxfDB8LTF8fDI2NDE2NjN8aHR0cHM6Ly93ZWIuY2hhcml0eWVuZ2luZS5uZXQvRGVmYXVsdC5hc3B4P3RzaWQ9NjY3NHwwfDB8MHw5MDN8MHwwfHwzMDE0MDY1" target="_blank" (click)="trackDonateClick()">
                                <div class="button topDonate">
                                    DONATE NOW
                                </div>
                            </a>
                        </div>
                        <div class="videoThumbHolder">
                            <div class="videoThumb" (click)="openVideo()">
                                <!-- <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/video-thumbnail.png" alt="" (load)="onImageLoad()"> -->
                                <video #loopvideo src="https://d3szci3apkzsqe.cloudfront.net/assets/video/lionsgate_celebs_loop_2.mp4" muted autoplay playsinline loop ></video>
                                <div class="clickto desktopOnly">
                                    CLICK TO WATCH
                                </div>
                                <div class="clickto mobileOnly">
                                    TAP TO WATCH
                                </div>
                                <div class="playButton" >
                                    <div class="playIcon"></div>
                                </div>
                            </div>
                        </div>
                        <div class="tyText">
                            TO EVERYONE THAT WATCHED <br>
                            <br>
                            TO EVERYONE THAT SUPPORTED
                        </div>
                        <div class="wrLogoHolder">
                            <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/thank-you.png" alt="" (load)="onImageLoad()"> 
                        </div>
                        

                    </div>
                    <div class="p2" *ngIf="!movieService.currentMovie.expired">
                        <a [href]="movieService.currentMovie.url" target="_blank" (click)="trackStreamClick(movieService.currentMovie.url)">
                            <div class="button streamLive">
                                {{movieService.currentMovie.running ? 'WATCH NOW' : 'STREAM LIVE' }}
                            </div>
                        </a>
                    </div>
                    <div class="pDonate" [class]="{'expired' : movieService.currentMovie.expired}" *ngIf="!movieService.currentMovie.expired">
                        <div class="dText desktopOnly" *ngIf="!movieService.currentMovie.expired">
                            Please join us to help the theater <br>
                            employees furloughed by the<br> 
                            COVID-19 crisis
                        </div>
                        <!-- <div class="dText desktopOnly" *ngIf="movieService.currentMovie.expired">
                            Please join us to help the theater <br> 
                            employees furloughed by the <br>  COVID-19 crisis by donating to the <br> <b>Will Rogers Motion Picture<br> Pioneers Foundation</b>
                        </div> -->
                        <div class="dText mobileOnly" *ngIf="!movieService.currentMovie.expired">
                            Please join us to help the theater employees<br>
                            furloughed by the COVID-19 crisis
                        </div>
                        <!-- <div class="dText mobileOnly" *ngIf="movieService.currentMovie.expired">
                            Please join us to help the theater employees<br> furloughed by the COVID-19 crisis by donating to <br>the <b>Will Rogers Motion Picture Pioneers Foundation</b>
                        </div> -->
                        <div class="donateHolder" [class]="{'pdd' : movieService.currentMovie.expired }">
                            <a href="http://smartmailerclick.bisglobal.net/act_linkClick.aspx?sm_guid=MzMzMDMxfDB8LTF8fDI2NDE2NjN8aHR0cHM6Ly93ZWIuY2hhcml0eWVuZ2luZS5uZXQvRGVmYXVsdC5hc3B4P3RzaWQ9NjY3NHwwfDB8MHw5MDN8MHwwfHwzMDE0MDY1" target="_blank" (click)="trackDonateClick()">
                                <div class="button topDonate">
                                    DONATE NOW
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="p3 snackWrapper" *ngIf="!movieService.currentMovie.expired">
                        <div class="snacksTitle">
                            Get your snacks ahead of time
                        </div>
                        <div class="snacksHolder">
                            <div class="snack">
                                <div class="simg" (click)="openPopup()">
                                    <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/Popcornopolis-logo.png" alt="">
                                </div>
                               
                            </div>
                            <div class="snack">
                                <a href="http://snacknation.com/movie" target="_blank" (click)="trackSnackNation()">
                                    <div class="simg">
                                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/snacknation-Stacked-logo.png" alt="">
                                    </div>
                                </a>
                                
                            </div>
                        </div>
                        <div class="afterSnack">
                            Ships in 2-3 days (US only)

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="middleWrapper" *ngIf="!movieService.currentMovie.expired">
        <img class="desktopOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-middle-v2.jpg" alt="" (load)="onImageLoad()">
        <img class="mobileOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-middle-v3.jpg" alt="" (load)="onImageLoad()">

        <div class="middleHolder" [class]="{'expired' : movieService.currentMovie.expired}">
            <div class="middleTitleHolder stagger">
                <div class="left">
                    <div class="subtitle">
                        THE FREE MOVIE EVENT
                    </div>
                    <div class="title">
                        A Night At the Movies...Together!
                    </div>
                </div>
                <div class="right desktopOnly">
                    <div class="youtubeIcon"></div>
                </div>
            </div>
            <div class="middleText stagger">
                A star-studded celebration of the theatrical filmgoing experience <b>hosted by Jamie Lee Curtis</b> and brought to you <b>LIVE</b>. Benefitting the <b>Will Rogers Motion Picture Pioneers Foundation</b>, dedicated to helping people who work in the motion picture industry and currently providing financial assistance to <b>theater employees furloughed by the COVID-19 crisis</b>. 
            </div>
            
            <div class="donateWrapper stagger">
                <div class="willRogersLogo">
                    <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/wilrogers.gif" alt="">
                </div>
                <div class="centered ">
                    <!-- <a href="https://web.charityengine.net/Default.aspx?tsid=6674" target="_blank"> -->
                    <a href="http://smartmailerclick.bisglobal.net/act_linkClick.aspx?sm_guid=MzMzMDMxfDB8LTF8fDI2NDE2NjN8aHR0cHM6Ly93ZWIuY2hhcml0eWVuZ2luZS5uZXQvRGVmYXVsdC5hc3B4P3RzaWQ9NjY3NHwwfDB8MHw5MDN8MHwwfHwzMDE0MDY1" target="_blank" (click)="trackDonateClick()">
                        <div class="button donate">
                            DONATE NOW
                        </div>
                    </a>
                    
                </div>
            </div>
            <div class="donateText stagger">
                Join LIONSGATE in supporting the <b>Will Rogers Motion Pictures Pioneers Foundation</b>, in partnership with:
            </div>
            <div class="centered stagger">
                <div class="donatePartners">
                    <!-- <img class="desktopOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/partners.png" alt=""> -->
                    <!-- <img class="mobileOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/partners.png" alt=""> -->
                    <div class="partner"> <!--fd -->
                        <!-- <div class="fandango"></div> -->
                        <img class="fandangoImage" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/fandango-logo.png" alt="">
                    </div>
                    <!-- <div class="partner">
                        <img class="youtube" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/yt_logo_rgb_dark.png" alt="">
                    </div> -->
                    <!-- <div class="partner amc">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/amc_logo.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/regal_logo.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/cinemarkLogo.png" alt="">
                    </div> -->
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/nato.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/DMM-Logo.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/watchmojo-logo.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/uni-logo.png" alt="">
                    </div>
                    <div class="partner ihart">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/iHeartMedia-logo.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/Popcornopolis-logo.png" alt="">
                    </div>
                    <div class="partner">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/snacknation-Stacked-logo.png" alt="">
                    </div>
                </div>
            </div>
            <div class="logo2Placeholder"></div>
    
        </div>
    </div>

    <div class="bottomWrapper">
        <img class="desktopOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-bottom.jpg" alt="" (load)="onImageLoad()" *ngIf="!movieService.currentMovie.expired">
        <img class="mobileOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-bottom-v3.jpg" alt="" (load)="onImageLoad()" *ngIf="!movieService.currentMovie.expired">

        <img class="desktopOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/bg-bottom-thankyou-short.jpg" alt="" (load)="onImageLoad()" *ngIf="movieService.currentMovie.expired">
        <img class="mobileOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/bg-bottom-thankyou-short.jpg" alt="" (load)="onImageLoad()" *ngIf="movieService.currentMovie.expired">

        <div class="bottomHolder stagger" >
            <div class="dateHolder desktopOnly" *ngIf="!movieService.currentMovie.expired">
                <div class="date1">
                    EVERY FRIDAY NIGHT
                </div>
                <div class="date2">
                    at 6:00PM PT / 9:00PM ET
                </div>
            </div>

            <div class="endBottomHolder " *ngIf="movieService.currentMovie.expired">
                <div class="lionsgateLive desktopOnly">
                    <span class="lg">LIONSGATE </span> <span class="lglive">LIVE</span>
                </div>

                <div class="endSplit">
                    <div class="eleft">
                        <div class="endTitle">
                            Thanks to all who joined us for Lionsgate Live!
                        </div> 

                        <div class="endText">
                            Please consider supporting furloughed theater employees by donating to the Will Rogers Motion Picture Pioneers Foundation.
                        </div>
                    </div>    
                    <div class="eright mobileOnly">
                        <div class="wrImgHolder">
                            <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/willrogers_logo.png" alt="">
                        </div>
                        <div class="donateHolder smaller" >
                            <a href="http://smartmailerclick.bisglobal.net/act_linkClick.aspx?sm_guid=MzMzMDMxfDB8LTF8fDI2NDE2NjN8aHR0cHM6Ly93ZWIuY2hhcml0eWVuZ2luZS5uZXQvRGVmYXVsdC5hc3B4P3RzaWQ9NjY3NHwwfDB8MHw5MDN8MHwwfHwzMDE0MDY1" target="_blank" (click)="trackDonateClick()">
                                <div class="button topDonate">
                                    DONATE NOW
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="endCU">
                    We look forward to seeing you all at the movies soon!
                </div>
                
            </div>
    
            <div class="posterHolder stagger" *ngIf="!movieService.currentMovie.expired">
                <div class="posterItem" [ngClass]="{'ended' : movieService.movies[0].expired}">
                    <div class="poster">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/hunger-games-v2.png" alt="">
                    </div>
                    <div class="posterDate">
                        {{movieService.movies[0].displDate}}
                    </div>
                    <div class="posterTitle">
                        The Hunger Games
                    </div>
                </div>
    
                <div class="posterItem" [ngClass]="{'ended' : movieService.movies[1].expired}">
                    <div class="poster">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/dirty-dancing-v2.png" alt="">
                    </div>
                    <div class="posterDate">
                        {{movieService.movies[1].displDate}}
                    </div>
                    <div class="posterTitle">
                        Dirty Dancing
                    </div>
                </div>
    
                <div class="posterItem" [ngClass]="{'ended' : movieService.movies[2].expired}">
                    <div class="poster">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/lalaland.png" alt="">
                    </div>
                    <div class="posterDate">
                        {{movieService.movies[2].displDate}}
                    </div>
                    <div class="posterTitle">
                        LA LA LAND
                    </div>
                </div>
    
                <div class="posterItem" [ngClass]="{'ended' : movieService.movies[3].expired}">
                    <div class="poster">
                        <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/johnwick-v3.png" alt="">
                    </div>
                    <div class="posterDate">
                        {{movieService.movies[3].displDate}}
                    </div>
                    <div class="posterTitle">
                        John Wick
                    </div>
                </div>
            </div>
    
            <div class="footer">
                <div class="footerLine">
                    <img class="desktopOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/line.png" alt="">
                    <img class="mobileOnly" src="https://d3szci3apkzsqe.cloudfront.net/assets/img/mobile/line.png" alt="">
                </div>
                <div class="top">
                    <a href="https://www.lionsgate.com" target="_blank">
                        <div class="lionsgate"></div>
                    </a>
                    <a href="https://www.facebook.com/lionsgate/" target="_blank">
                        <div class="facebook"></div>
                    </a>
                    <a href="https://twitter.com/lionsgate/" target="_blank">
                        <div class="twitter"></div>
                    </a>
                    <a href="https://www.instagram.com/lionsgate/" target="_blank">
                        <div class="instagram"></div>
                    </a>
                </div>
                <div class="legal">
                    <div class="copyright">
                        TM & © {{ year }} Lions Gate Entertainment Inc. 			  	
                    </div>
                    <a href="https://www.lionsgate.com/legal/privacy-policy" target="_blank">Privacy Policy</a>
                    <a href="https://www.lionsgate.com/legal/terms-of-use" target="_blank">Terms of Use</a>
                    <a href="https://www.lionsgate.com/legal/do-not-sell-my-information" target="_blank">Do not sell my info</a>

                    <div id="teconsent"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="consent_blackbar"></div>
 
<div class="popup popupVideo" [ngClass]="{'open' : isPopupVideoOpen }" >
    <div class="closeHolder"  (click)="closeVideo()">
        <div class="closeTxt">CLOSE</div>
        <div class="closeX"></div>
    </div>
    <youtube-player #youtubePlayer videoId="WLsdTd_DEf8" [width]="ytWidth" [height]="ytHeight" (stateChange)="ytStateChange($event)"></youtube-player>
</div>

<div class="popup" [ngClass]="{'open' : isPopupOpen }" >
    <div class="popupBg" (click)="closePopup()"></div>
    <div class="popupHolder">
        <div class="closeHolder"  (click)="closePopup()">
            <div class="closeTxt">CLOSE</div>
            <div class="closeX"></div>
        </div>
        <div class="popcornLogo">
            <img src="https://d3szci3apkzsqe.cloudfront.net/assets/img/Popcornopolis-logo.png" alt="">
        </div>
        <div class="text1">
            15% off site-wide + FREE SHIPPING
        </div>
        <div class="codeHolder">
            <div class="label">USE CODE:</div>
            <div class="code">MOVIENIGHT</div> 
            <div class="dates">Offer Dates: 4/16 - 5/10</div>
        </div>
        <div class="text2">
            10% OF SALES USING <u>MOVIENIGHT</u> CODE GO TO <b>WILL ROGERS MOTION PICTURE PIONEERS FUND</b>
        </div>

        <a href="https://www.popcornopolis.com/" target="_blank" class="centered pupBtn" (click)="trackPopcornpolis()">        
            <div class="button">
                VISIT SITE
            </div>
        </a>
    </div>
</div>