import { Component, OnInit, Input, Output, EventEmitter, QueryList, ElementRef } from '@angular/core';

import * as moment from 'moment';
import { TweenMax, TimelineMax } from 'gsap';



@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {
    @Input() countdownDate: string
    public day: string = "-"
    public hour: string = "-"
    public minute: string = "-"
    public second: string = "-"
    private intervalNo: any

    constructor(
        
    ) { }

    ngOnInit(): void {
        this.setup()
    }

    setup() {
        let eventTime = new Date(this.countdownDate).getTime();
        let currentTime = new Date().getTime();
        let hash = decodeURIComponent(window.location.hash).replace('#', '')
        if(hash.length> 11) {
            try {
                currentTime =  new Date(hash).getTime();
            }
            catch(e) {}
        }
        let diffTime = eventTime - currentTime;
        let duration = moment.duration(diffTime, 'milliseconds');
        let interval = 1000;
        let count = interval;

        this.intervalNo = setInterval(() => {
            this.day = "00"
            this.hour = "00"
            this.minute = "00"
            this.second = "00"

            duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
            if (duration.days() >= 0 && duration.hours() >= 0 && duration.minutes() >= 0 && duration.seconds() >= 0) {
                this.day = '' + duration.days()
                if(parseInt(this.day) < 10) {
                    this.day = "0" + this.day
                }

                this.hour = '' + duration.hours();
                if (parseInt(this.hour) < 10) {
                    this.hour = "0" + this.hour;
                }
                this.minute = '' + duration.minutes();
                if (parseInt(this.minute) < 10) {
                    this.minute = "0" + this.minute;
                }
                this.second = '' + duration.seconds();
                if (parseInt(this.second) < 10) {
                    this.second = "0" + this.second;
                }
            }
            else {
                this.day = "0"
                this.hour = "00"
                this.minute = "00"
                this.second = "00"
                clearInterval(this.intervalNo);
            }

        }, interval);
    }
}   
